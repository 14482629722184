<template>
  <div>
    <!------------------------------ Filter Card Start ----------------------------->

    <filter-card
      v-if="groupItems"
      :statuses="statusItems"
      :status="statusFilter"
      :groups="groupItems"
      :group="groupFilter"
      :incometypes="incometypes"
      @updateQuery="setQuery"
      @switchDayWeek="setWeeklyData"
      @selectDate="dateChanged"
      @createActions="handelSubmitActions"
      @updateStatus="handelFilterStatus"
      @updateGroup="handelFilterGroup"
    />

    <!------------------------------ Day Content Start ----------------------------->
    <div v-if="!isWeek">
      <!---------------------------- Summary View Card Start ----------------------->
      <b-row>
        <b-col
          lg="4"
          class="match-height"
        >
          <div class="d-flex flex-column justify-content-between">
            <LabelsPieCard
              :data="interestsCounts"
              class="mb-1"
            />
            <LabelsPieCard
              :data="penaltiesCounts"
              class="mb-1"
            />
          </div>
        </b-col>
        <b-col
          lg="2"
          class="pb-1 match-height"
          style="height: 320px"
        >
          <VerticalLabelsCard
            :data="apeCounts"
            class="h-100"
          />
        </b-col>
        <b-col
          lg="6"
          class="pb-1 match-height"
          style="height: 320px"
        >
          <ListCard
            :data="notesNExpenses"
            class="h-100"
            :incometypes="incometypes"
            :select-date="selectedDate"
            @createActions="handelSubmitActions"
          />
        </b-col>
      </b-row>
      <!---------------------------- Paid list Start ---------------------->
      <b-row>
        <b-col lg="12">
          <b-card
            no-body
            class="overflow-hidden mb-1"
          >
            <h5 class="mt-1 ml-2 mb-1">
              {{ $t('due date list') }}
            </h5>
            <b-table
              id="due-date-table"
              responsive
              :items="filteredDueDate"
              :fields="allTableInfo.fields"
              :filter="searchQuery"
              :caption-top="true"
              table-class="custom-table"
              thead-class="text-nowrap"
              :hover="true"
              show-empty
              :empty-text="$t('no matching records found')"
            >
              <!-- Column - Status Start -->
              <template #cell(status)="data">
                <div style="width: 90px;">
                  <b-badge
                    v-if="statusItems.find(d => d.key === data.value)"
                    :variant="`light-${statusItems.find(d => d.key === data.value).type}`"
                    class="circle-rounded px-1 font-weight-normal mb-50"
                  >
                    {{ $t(statusItems.find(d => d.key === data.value).name.toLowerCase()) }}
                  </b-badge>
                </div>
              </template>

              <template #head()="data">
                <div>
                  {{ $t(data.label) }}
                </div>
              </template>

              <template #cell(note)="data">
                <b-link
                  v-b-modal.modal-note
                  class="link-text"
                  :disabled="!$can('create', 'dashboard')"
                  @click="handleNoteModal(data.item)"
                >
                  <div
                    class="text-truncate-container"
                  >
                    <p
                      v-if="data.item.debtInfo.note"
                      class="m-0"
                    >
                      {{ data.item.debtInfo.note }}
                    </p>
                    <p
                      v-else
                      class="ml-2"
                    >
                      <feather-icon
                        v-if="$can('create', 'dashboard')"
                        icon="Edit3Icon"
                        size="16"
                        class="cursor-pointer"
                      />
                    </p>
                  </div>
                </b-link>
              </template>

              <template #cell(user)="data">
                <div
                  style="width: 130px;"
                  class="d-flex align-items-center"
                >
                  <div class="pr-50">
                    <b-avatar
                      :src="data.item.photoURL"
                    />
                  </div>
                  <div>
                    <b-link
                      :to="{ path: 'customer', query: { id: data.item.cid } }"
                    >
                      <h6 class="mb-0 text-primary text-nowrap">
                        {{ data.item.basicInfo.fullName }}
                      </h6>
                    </b-link>
                    <small class="m-0">{{ data.item.group }} {{ data.item.basicInfo.accountNumber }}</small>
                  </div>
                </div>
              </template>

              <template #cell(dueDateTime)="data">
                <div
                  style="width: 130px;"
                  class="text-nowrap text-center"
                >
                  {{ data.item.loan.dueDateTime | moment('YYYY/MM/DD HH:mm') }} (<span class="text-success">+ {{ data.item.loan.paymentDuration }}</span>)
                </div>
              </template>

              <template #cell(principal)="data">
                <div
                  style="width: 100px;"
                  class="text-right float-right"
                >
                  {{ '$' + numberWithCommas(data.item.loan.principal) }}
                </div>
              </template>

              <template #cell(payable)="data">
                <div
                  style="width: 80px;"
                  class="text-right float-right"
                >
                  {{ '$' + numberWithCommas(data.item.loan.payable) }}
                </div>
              </template>

              <template #cell(paid)="data">
                <div>
                  <b-link
                    v-b-modal.model-pay-interest
                    href="#"
                    class="link-text"
                    @click="handlePaidModal(data.item)"
                  >
                    <feather-icon
                      v-if="$can('create', 'transactions')"
                      icon="PlusCircleIcon"
                      size="18"
                      class="cursor-pointer"
                    />
                  </b-link>
                </div>
              </template>
            </b-table>
          </b-card>
          <b-modal
            id="add-penalty"
            :title="$t('add penalty')"
            :hide-header-close="true"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"
            footer-class="justify-content-end flex-row-reverse"
            :ok-title="$t('add')"
            :cancel-title="$t('cancel')"
            cancel-variant="outline-secondary"
            @ok="addPenalty"
          >
            <b-form>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('date')"
                    label-for="add-date"
                  >
                    <b-form-datepicker
                      id="add-date"
                      v-model="addPenaltyInfo.date"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('time')"
                    label-for="add-time"
                  >
                    <b-form-timepicker
                      id="add-time"
                      v-model="addPenaltyInfo.time"
                      locale="en"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('amount')"
                    label-for="penalty-amount"
                  >
                    <b-input-group
                      append=".00"
                    >
                      <b-form-input
                        id="penalty-amount"
                        v-model="addPenaltyInfo.penalty"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('note')"
                    label-for="textarea-note"
                  >
                    <b-form-textarea
                      id="textarea-note"
                      v-model="addPenaltyInfo.note"
                      rows="3"
                      :placeholder="$t('content')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
          <b-modal
            id="modal-paid"
            :title="$t('pay interest')"
            :hide-header-close="true"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"
            footer-class="justify-content-end flex-row-reverse"
            :ok-title="$t('pay')"
            :cancel-title="$t('cancel')"
            cancel-variant="outline-secondary"
            @ok="paidSubmit('interest')"
          >
            <b-form>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('date')"
                    label-for="add-date"
                  >
                    <b-form-datepicker
                      id="add-date"
                      v-model="paidInfo.date"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('time')"
                    label-for="add-time"
                  >
                    <b-form-timepicker
                      id="add-time"
                      v-model="paidInfo.time"
                      locale="en"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('amount')"
                    label-for="paid-amount"
                  >
                    <b-input-group
                      append=".00"
                    >
                      <b-form-input
                        id="paid-amount"
                        v-model="paidInfo.paid"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('note')"
                    label-for="textarea-note"
                  >
                    <b-form-textarea
                      id="textarea-note"
                      v-model="paidInfo.note"
                      rows="3"
                      :placeholder="$t('content')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
          <!-- Paid modal in all table End -->
          <!-- Note modal in all table Start -->
          <b-modal
            id="modal-note"
            :title="$t('add edit')"
            :hide-header-close="true"
            footer-class="justify-content-end flex-row-reverse"
            :ok-title="$t('save')"
            :cancel-title="$t('cancel')"
            cancel-variant="outline-secondary"
            @ok="noteSubmit"
          >
            <b-form>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('note')"
                    label-for="textarea-note"
                  >
                    <b-form-textarea
                      id="textarea-note"
                      v-model="customerNote.note"
                      rows="8"
                      placeholder="Content"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
          <!-- Note modal in all table End -->
        </b-col>
      </b-row>
      <!---------------------------- penalty list Start ------------------>
      <b-row>
        <b-col>
          <b-card
            no-body
            class="mb-1"
            style="min-height: 200px;"
          >
            <h5 class="mt-1 ml-2 mb-1">
              {{ $t('penalty list') }}
            </h5>
            <b-table
              id="penalty-table"
              responsive
              :items="filteredPenalty"
              :fields="penaltyTableInfo.fields"
              :filter="searchQuery"
              :caption-top="true"
              table-class="custom-table"
              thead-class="text-nowrap"
              :hover="true"
              show-empty
              :empty-text="$t('no matching records found')"
            >
              <!-- Column - Status Start -->
              <template #cell(status)="data">
                <div style="width: 90px;">
                  <b-badge
                    v-if="statusItems.find(d => d.key === data.value)"
                    :variant="`light-${statusItems.find(d => d.key === data.value).type}`"
                    class="circle-rounded px-1 font-weight-normal mb-50"
                  >
                    {{ $t(statusItems.find(d => d.key === data.value).name.toLowerCase()) }}
                  </b-badge>
                </div>
              </template>

              <template #cell(dueDateTime)="data">
                <div
                  v-if="data.item.loan.dueDateTime"
                  style="width: 130px;"
                  class="text-nowrap text-center"
                >
                  {{ data.item.loan.dueDateTime | moment('YYYY/MM/DD HH:mm') }} (<span class="text-danger">{{ momentDiff(data.item.loan.dueDateTime) }}</span>)
                </div>
              </template>
              <!-- Column - Status End -->
              <!-- Column - User Start -->
              <template #cell(user)="data">
                <div
                  style="width: 130px;"
                  class="d-flex align-items-center"
                >
                  <div class="pr-50">
                    <b-avatar
                      :src="data.item.photoURL"
                    />
                  </div>
                  <div>
                    <b-link
                      :to="{ path: 'customer', query: { id: data.item.cid } }"
                    >
                      <h6 class="mb-0 text-primary text-nowrap">
                        {{ data.item.basicInfo.fullName }}
                      </h6>
                    </b-link>
                    <small class="m-0">{{ data.item.group }} {{ data.item.basicInfo.accountNumber }}</small>
                  </div>
                </div>
              </template>
              <!-- Column - User End -->
              <!-- Column - Loan Start -->
              <template #cell(principal)="data">
                <div
                  style="width: 100px;"
                  class="text-right float-right"
                >
                  {{ '$' + numberWithCommas(data.item.loan.principal) }}
                </div>
              </template>

              <!-- Column - penalty Start -->
              <template #cell(penalty)="data">
                <div
                  style="width: 80px;"
                  class="text-right float-right"
                >
                  {{ '$' + numberWithCommas(data.item.loan.penalty) }}
                </div>
              </template>

              <!-- Column - penalty End -->
              <!-- Column - Paid Start -->
              <template #cell(paid)="data">
                <div>
                  <b-link
                    v-b-modal.modal-penalty-paid
                    href="#"
                    class="link-text"
                    @click="handlePenaltyPaidModal(data.item)"
                  >
                    <feather-icon
                      v-if="$can('create', 'transactions')"
                      icon="PlusCircleIcon"
                      size="18"
                      class="cursor-pointer"
                    />
                  </b-link>
                </div>
              </template>
              <!-- Column - Paid End -->
              <!-- Column - Note Start -->
              <template #cell(note)="data">
                <b-link
                  v-b-modal.modal-note
                  href="#"
                  class="link-text"
                  :disabled="!$can('create', 'dashboard')"
                  @click="handleNoteModal(data.item)"
                >
                  <div
                    class="text-left text-truncate-container"
                  >
                    <p
                      v-if="data.item.debtInfo.note"
                      class="m-0"
                    >
                      {{ data.item.debtInfo.note }}
                    </p>
                    <p
                      v-else
                      class="ml-2"
                    >
                      <feather-icon
                        v-if="$can('create', 'dashboard')"
                        icon="Edit3Icon"
                        size="16"
                        class="cursor-pointer"
                      />
                    </p>
                  </div>
                </b-link>
              </template>

              <template #cell(action)="data">
                <b-dropdown
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  toggle-class="px-0 py-50 bg-transparent"
                  variant="flat-dark"
                  no-caret
                  right
                >
                  <template
                    v-slot:button-content
                  >
                    <feather-icon
                      v-if="$can('create', 'dashboard')"
                      :id="`box-pop-menu-${data.item.id}`"
                      icon="MoreVerticalIcon"
                      size="18"
                      class="cursor-pointer"
                    />
                  </template>
                  <b-dropdown-item
                    v-b-modal.add-penalty
                    @click="handleAddPenaltyModal(data.item)"
                  >
                    {{ $t('add penalty') }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <!-- Column - Note End -->
              <template #head()="data">
                <div>
                  {{ $t(data.label) }}
                </div>
              </template>
            </b-table>
          </b-card>
          <!-- Paid modal in all table Start -->
          <b-modal
            id="modal-penalty-paid"
            :title="$t('pay penalty')"
            :hide-header-close="true"
            :no-close-on-backdrop="true"
            :no-close-on-esc="true"
            footer-class="justify-content-end flex-row-reverse"
            :ok-title="$t('pay')"
            :cancel-title="$t('cancel')"
            cancel-variant="outline-secondary"
            @ok="paidSubmit('penalty')"
          >
            <b-form>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('date')"
                    label-for="paid-date"
                  >
                    <b-form-datepicker
                      id="paid-date"
                      v-model="penaltyPaidInfo.date"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="$t('time')"
                    label-for="paid-time"
                  >
                    <b-form-timepicker
                      id="paid_time"
                      v-model="penaltyPaidInfo.time"
                      locale="en"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('amount')"
                    label-for="paid-amount"
                  >
                    <b-input-group
                      append=".00"
                    >
                      <b-form-input
                        id="paid-amount"
                        v-model="penaltyPaidInfo.paid"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('note')"
                    label-for="textarea-note"
                  >
                    <b-form-textarea
                      id="textarea-note"
                      v-model="penaltyPaidInfo.note"
                      rows="3"
                      :placeholder="$t('content')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
          <!-- Paid modal in all table End -->
          <PayInterest
            v-if="paidInfo.customer"
            :customer-data="paidInfo.customer"
            :user-data="user"
          />
        </b-col>
      </b-row>
    </div>
    <!------------------------------ Week Content Start ----------------------------->
    <div v-else>
      <b-row />
      <b-card
        no-body
        class="border"
      >
        <WeekLogView
          :statuses="statusItems"
          :week-dates="thisWeek"
          :daily-due-date-customers="getAllDailyDueDateCustomers"
          :modal-note="customerNote"
          :modal-paid="paidInfo"
          :filter-status="statusFilter"
          :filter-group="groupFilter"
          @handleNote="handleNoteModal"
          @handlePaid="handlePaidModal"
          @updateNote="noteSubmit"
          @payInterest="paidSubmit('interest')"
        />
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BTable, BDropdown, BDropdownItem, BLink, BModal, BForm, BFormGroup, BFormInput, BFormDatepicker, BFormTimepicker, BInputGroup, BAvatar, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { mapGetters } from 'vuex'
import { add } from 'mathjs'
import FilterCard from './components/FilterCard.vue'
import LabelsPieCard from './components/LabelsPieCard.vue'
import VerticalLabelsCard from './components/VerticalLabelsCard.vue'
import ListCard from './components/ListCard.vue'
import WeekLogView from './components/WeekLogView.vue'

import PayInterest from './components/model/PayInterest.vue'

export default {
  components: {
    FilterCard,
    LabelsPieCard,
    VerticalLabelsCard,
    ListCard,
    WeekLogView,
    BRow,
    BCol,
    BCard,
    BBadge,
    BTable,
    BDropdown,
    BDropdownItem,
    BLink,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormTimepicker,
    BInputGroup,
    BAvatar,
    BFormTextarea,
    PayInterest,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusFilter: null,
      groupFilter: null,
      searchQuery: '',
      selectedDate: this.$moment().format('YYYY-MM-DD'),
      thisWeek: [],
      isWeek: false,
      allTableInfo: {
        fields: [
          {
            key: 'status', label: 'status', sortable: true, tdClass: 'tdwidth',
          },
          { key: 'user', label: 'user', tdClass: 'tdwidth' },
          {
            key: 'dueDateTime', label: 'next payment', thClass: 'text-center', tdClass: 'tdwidth',
          },
          {
            key: 'principal', label: 'total loan', sortable: true, thClass: 'text-right', tdClass: 'tdwidth pr-1',
          },
          {
            key: 'payable', label: 'payable', sortable: true, thClass: 'text-right', tdClass: 'tdwidth pr-1',
          },
          {
            key: 'paid',
            label: 'paid',
            sortable: true,
            thClass: 'text-center',
            tdClass: 'text-center tdwidth',
          },
          { key: 'note', label: 'note' },
        ],
      },
      penaltyTableInfo: {
        fields: [
          {
            key: 'status', label: 'status', sortable: true, thClass: 'tdwidth',
          },
          { key: 'user', label: 'user', thClass: 'tdwidth' },
          {
            key: 'dueDateTime', label: 'next payment', thClass: 'text-center tdwidth', tdClass: 'tdwidth',
          },
          {
            key: 'principal', label: 'total loan', sortable: true, thClass: 'text-right', tdClass: 'tdwidth pr-1',
          },
          {
            key: 'penalty',
            label: 'penalty',
            sortable: true,
            thClass: 'text-right',
            tdClass: 'tdwidth pr-1',
          },
          {
            key: 'paid',
            label: 'paid',
            sortable: true,
            tdClass: 'text-center tdwidth',
          },
          { key: 'note', label: 'note' },
          {
            key: 'action', label: 'action', thClass: 'text-center', tdClass: 'text-center',
          },
        ],
      },
      statusItems: [
        {
          label: this.$t('inactive'),
          value: 'inactive',
          key: 'inactive',
          name: 'inactive',
          type: 'secondary',
        },
        {
          label: this.$t('active'),
          value: 'active',
          key: 'active',
          name: 'active',
          type: 'info',
        },
        {
          label: this.$t('processing'),
          value: 'processing',
          key: 'processing',
          name: 'processing',
          type: 'primary',
        },
        {
          label: this.$t('observed'),
          value: 'observed',
          key: 'observed',
          name: 'observed',
          type: 'warning',
        },
        {
          label: this.$t('closed'),
          value: 'closed',
          key: 'closed',
          name: 'closed',
          type: 'success',
        },
        {
          label: this.$t('bad debt'),
          value: 'bad_debt',
          key: 'bad_debt',
          name: 'bad debt',
          type: 'danger',
        },
        {
          label: this.$t('dishonored'),
          value: 'dishonored',
          key: 'dishonored',
          name: 'dishonored',
          type: 'danger',
        },
      ],
      incometypes: [
        /* {
          key: 'interest',
          name: 'interest',
        }, */
        {
          key: 'expense',
          name: 'expense',
        },
        {
          key: 'note',
          name: 'message',
        },
        /* {
          key: 'penalty',
          name: 'penalty',
        }, */
      ],
      addPenaltyInfo: {
        customer: null,
        date: this.$moment().format('YYYY-MM-DD'),
        time: this.$moment().format('HH:mm'),
        penalty: '',
        note: '',
      },
      paidInfo: {
        customer: null,
        date: this.$moment().format('YYYY-MM-DD'),
        time: this.$moment().format('HH:mm'),
        paid: '',
        note: '',
      },
      penaltyPaidInfo: {
        customer: null,
        date: this.$moment().format('YYYY-MM-DD'),
        time: this.$moment().format('HH:mm'),
        paid: '',
        note: '',
      },
      noteInfo: {
        date: '',
        time: '',
        note: '',
      },
      customerNote: {
        note: '',
        cid: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      getGroups: 'getGroups',
      getDailyDueDateCustomers: 'getDailyDueDateCustomers',
      getDailyPenaltyCustomers: 'getDailyPenaltyCustomers',
      getDailyNotes: 'getDailyNotes',
      getDailyTransactionsByType: 'getDailyTransactionsByType',
      getDailyTransactions: 'getDailyTransactions',
      getAllDailyDueDateCustomers: 'getAllDailyDueDateCustomers',
    }),
    groupItems() {
      return this.getGroups.map(({ groupName }) => ({ label: groupName, value: groupName }))
    },
    notesNExpenses() {
      const ne = [...this.getDailyNotes(this.selectedDate), ...this.getDailyTransactionsByType({ date: this.selectedDate, type: 'expense' })]
      return ne.sort((a, b) => b.createdAt - a.createdAt)
    },
    interestsCounts() {
      let amount = 0
      let received = 0

      const interests = this.getDailyTransactionsByType({ date: this.selectedDate, type: 'interest' }) || []
      interests.forEach(tran => {
        amount = +add(amount, tran.payable)
        received = +add(received, tran.paymentReceived)
      })
      const customers = this.getDailyDueDateCustomers(this.selectedDate) || []
      customers.forEach(customer => {
        amount = +add(amount, customer.loan.payable)
      })

      return {
        amount: {
          label: 'receivable interests',
          value: amount,
        },
        received: {
          label: 'received',
          value: received,
        },
      }
    },
    penaltiesCounts() {
      let amount = 0
      let received = 0

      const penalties = this.getDailyTransactionsByType({ date: this.selectedDate, type: 'penalty' }) || []
      penalties.forEach(tran => {
        amount = +add(amount, tran.paymentReceived)
        received = +add(received, tran.paymentReceived)
      })
      const customers = this.getDailyPenaltyCustomers(this.selectedDate) || []
      customers.forEach(customer => {
        if (customer.loan.penalty) {
          amount = +add(amount, customer.loan.penalty)
        }
      })

      return {
        amount: {
          label: 'receivable penalties',
          value: amount,
        },
        received: {
          label: 'received',
          value: received,
        },
      }
    },
    apeCounts() {
      let allowances = 0
      let expenses = 0
      let penalties = 0

      const interests = this.getDailyTransactionsByType({ date: this.selectedDate, type: 'interest' })
      interests.forEach(tran => {
        if (tran.allowance) allowances = +add(allowances, tran.allowance)
        if (tran.penalty) penalties = +add(penalties, tran.penalty)
      })

      const exps = this.getDailyTransactionsByType({ date: this.selectedDate, type: 'expense' })
      exps.forEach(tran => {
        expenses += add(expenses, tran.paid)
      })
      return {
        allowances,
        expenses,
        penalties,
      }
    },
    filteredPenalty() {
      const filtered = this.getDailyPenaltyCustomers(this.selectedDate) || []
      if (this.groupFilter && this.statusFilter) {
        return filtered.filter(customer => customer.group === this.groupFilter && customer.status === this.statusFilter)
      }
      if (this.groupFilter) {
        return filtered.filter(customer => customer.group === this.groupFilter)
      }
      if (this.statusFilter) {
        return filtered.filter(customer => customer.status === this.statusFilter)
      }
      return filtered
    },
    filteredDueDate() {
      const filtered = this.getDailyDueDateCustomers(this.selectedDate) || []
      if (this.groupFilter && this.statusFilter) {
        return filtered.filter(customer => customer.group === this.groupFilter && customer.status === this.statusFilter)
      }
      if (this.groupFilter) {
        return filtered.filter(customer => customer.group === this.groupFilter)
      }
      if (this.statusFilter) {
        return filtered.filter(customer => customer.status === this.statusFilter)
      }
      return filtered
    },
  },
  async mounted() {
    this.setWeeklyArray(this.selectedDate)
    this.fetchData(this.selectedDate, 'today')
  },
  methods: {
    momentDiff(date) {
      const check = this.$moment(date)
      const today = this.$moment()
      return check.diff(today, 'days')
    },
    fetchData(date, today) {
      return new Promise((resolve, reject) => {
        const all = today || date

        if (!this.getDailyDueDateCustomers(date)) {
          Promise.all([
            store.dispatch('fetchCustomers', {
              sid: this.user.sid, field: 'loan.dueDateTime', operator: '<', value: all, date, status: ['active', 'processing', 'observed'],
            }),
            store.dispatch('fetchCustomers', {
              sid: this.user.sid, field: 'loan.penalty', operator: '>', value: 0, date, status: ['active', 'processing', 'observed'],
            }),
            store.dispatch('fetchDailyTransactions', { sid: this.user.sid, date }),
            store.dispatch('fetchNotes', { sid: this.user.sid, date }),
          ]).then(() => {
            resolve()
          }).catch(err => {
            reject(err)
          })
        }
      })
    },
    setWeeklyData(value) {
      this.$set(this, 'isWeek', value)
      if (this.isWeek) {
        this.thisWeek.forEach(d => {
          store.dispatch('fetchCustomers', {
            sid: this.user.sid, field: 'loan.dueDateTime', operator: '<', value: d, date: d,
          })
        })
      }
    },
    setWeeklyArray(date) {
      const startOfWeek = this.$moment(date).startOf('isoWeek')
      const endOfWeek = this.$moment(date).endOf('isoWeek')

      const days = []
      let day = startOfWeek

      while (day <= endOfWeek) {
        days.push(this.$moment(day).format('YYYY-MM-DD'))
        day = day.clone().add(1, 'd')
      }
      this.thisWeek = days
    },
    dateChanged(date) {
      this.selectedDate = date
      this.setWeeklyArray(date)
      if (this.isWeek) {
        this.thisWeek.foreach(d => {
          store.dispatch('fetchCustomers', {
            sid: this.user.sid, field: 'loan.dueDateTime', operator: '<', value: d, date: d,
          })
        })
      } else {
        this.fetchData(date)
      }
    },
    handleStatusInAllTable(key, rowId) {
      const tableData = [...this.filteredDueDate]
      tableData.find(d => d.cid === rowId).status = key
      this.$set(this, 'allTableInfo', tableData)
    },
    handleStatusInpenaltyTable(key, rowId) {
      const tableData = { ...this.penaltyTableInfo }
      tableData.items.find(d => d.id === rowId).status = key
      this.$set(this, 'penaltyTableInfo', tableData)
    },
    handlePaidModal(data) {
      this.$set(this, 'paidInfo', {
        ...this.paidInfo,
        customer: data,
      })
    },
    handelFilterStatus(val) {
      this.statusFilter = val
    },
    handelFilterGroup(val) {
      this.groupFilter = val
    },
    handleAddPenaltyModal(data) {
      this.$set(this, 'addPenaltyInfo', {
        ...this.addPenaltyInfo,
        customer: data,
      })
    },
    handlePenaltyPaidModal(data) {
      this.$set(this, 'penaltyPaidInfo', {
        ...this.penaltyPaidInfo,
        customer: data,
      })
    },
    handleNoteModal(data) {
      if (data.debtInfo.note || data.debtInfo.note === '') {
        this.$set(this, 'customerNote', {
          note: data.debtInfo.note,
          cid: data.cid,
        })
      }
    },
    handelSubmitActions(data) {
      if (data.type === 'note') {
        store.dispatch('createNote', {
          ...data, sid: this.user.sid, uid: this.user.uid, userName: this.user.displayName,
        })
      } else if (data.type === 'expense') {
        store.dispatch('createExpense', {
          ...this.user, ...data,
        })
      }
    },
    paidSubmit(type) {
      if (type === 'interest' && this.paidInfo.paid) {
        const dateTime = this.$moment(`${this.paidInfo.date} ${this.paidInfo.time}`, 'YYYY-MM-DD HH:mm').format()
        const data = {
          ...this.user,
          customer: this.paidInfo.customer,
          cid: this.paidInfo.customer.cid,
          paid: this.paidInfo.paid,
          note: this.paidInfo.note,
          dateTime,
        }
        store.dispatch('payInterest', data)
      } else if (type === 'penalty' && this.penaltyPaidInfo.paid) {
        const dateTime = this.$moment(`${this.penaltyPaidInfo.date} ${this.penaltyPaidInfo.time}`, 'YYYY-MM-DD HH:mm').format()
        const data = {
          ...this.user,
          customer: this.penaltyPaidInfo.customer,
          cid: this.penaltyPaidInfo.customer.cid,
          paid: this.penaltyPaidInfo.paid,
          note: this.penaltyPaidInfo.note,
          dateTime,
        }
        store.dispatch('payPenalty', data)
      }
    },
    addPenalty() {
      if (this.addPenaltyInfo.penalty) {
        const dateTime = this.$moment(`${this.addPenaltyInfo.date} ${this.addPenaltyInfo.time}`, 'YYYY-MM-DD HH:mm').format()
        const data = {
          ...this.user,
          customer: this.addPenaltyInfo.customer,
          cid: this.addPenaltyInfo.customer.cid,
          penalty: this.addPenaltyInfo.penalty,
          note: this.addPenaltyInfo.note,
          dateTime,
        }
        store.dispatch('addPenalty', data)
      }
    },
    noteSubmit() {
      store.dispatch('updateCustomerNote', { ...this.customerNote, date: this.selectedDate })
    },
    setQuery(value) {
      this.searchQuery = value
    },
  },
}
</script>

<style lang="scss">
  .tdwidth {
    width: 10%
  }
  .text-truncate-container {
      width: 200px;
  }
  .text-truncate-container p {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
  }
</style>
